<template>
  <!--pages/vlogin.vue-->
  <div>
    <LoginForm />
  </div>
</template>

<script setup>
definePageMeta({
  layout: "public",
});

useSeoMeta({
  title: 'Dagknows - Login',
  ogTitle: 'Dagknows - Login',
  description: 'GenAI created and expert curated automation',
  ogDescription: 'GenAI created and expert curated automation',
  ogImage: 'https://www.dagknows.com/wp-content/uploads/2021/08/banner.svg',
  twitterCard: 'https://www.dagknows.com/wp-content/uploads/2021/08/banner.svg',
});

onMounted(async () => { 
  remove_leftover_tooltip();
  pageMountedCode();
});

onBeforeUnmount(async () => {
    pageUnmoutedCode();
});


const loginRedirect = useCookie('vloginRedirect', {
  'sameSite': 'lax'
});
if (loginRedirect.value == '') {
  loginRedirect.value = '/';
}

</script>
<style scoped></style>
